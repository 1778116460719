import styled from 'styled-components'

export const Component = styled.section`
  & {
    margin: 0 auto 15rem auto;
    padding: 0 3rem;
    max-width: 81.5rem;
  }
`

export const RichTextParagraph = styled.p`
  margin-bottom: 4rem;
  color: ${({ theme }) => theme.secondaryBlack};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 1.8rem;
  line-height: 1.5;
`

export const RichTextHeadingTwo = styled.h2`
  margin-bottom: 4rem;
  color: ${({ theme }) => theme.secondaryBlack};
  font-family: ${({ theme }) => theme.fontName};
  font-size: 2.6rem;
  font-weight: 700;
  line-height: 1.1;
  @media (min-width: ${({ theme }) => theme.breakpoints.medium}) {
    font-size: 4rem;
  }
`

export const RichTextHyperlink = styled.a`
  transition: 0.5s color ease;
  color: ${({ theme }) => theme.primaryGreen};
  font-family: ${({ theme }) => theme.fontName};
  font-weight: 700;
  text-decoration: none;
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.secondaryGreen};
  }
`

export const RichTextUl = styled.ul`
  margin-left: 3rem;
  margin-bottom: 4rem;
  li {
    position: relative;
    margin-bottom: 3rem;
    font-weight: 700;
  }
  li::before {
    position: absolute;
    content: '•';
    color: ${({ theme }) => theme.primaryBlue};
    font-weight: bold;
    font-size: 3rem;
    display: inline-block;
    width: 15px;
    top: 0;
    margin-left: -1em;
  }
`

export const RichTextOl = styled.ol`
  margin-left: 3rem;
  margin-bottom: 4rem;
  list-style: none;
  counter-reset: custom-counter;
  li {
    position: relative;
    counter-increment: custom-counter;
  }
  li::before {
    position: absolute;
    margin-left: -2em;
    content: counter(custom-counter) '. ';
    color: ${({ theme }) => theme.primaryBlue};
    font-family: ${({ theme }) => theme.fontName};
    font-weight: 700;
    font-size: 1.8rem;
  }
`
