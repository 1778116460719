import React from 'react'
import {
  Component,
  RichTextParagraph,
  RichTextOl,
  RichTextUl,
  RichTextHeadingTwo,
  RichTextHyperlink,
} from './body.styles'
import { RichTextReplacements, parseBuilderRichText } from '../../helpers/builderRichTextParser'
import { BuilderCustomComponentProps } from '../../types/builderio-ids'

interface BodyProps extends BuilderCustomComponentProps {
  text: string
}
const replacements: RichTextReplacements = [
  { nodeName: 'p', Component: RichTextParagraph },
  { nodeName: 'ol', Component: RichTextOl },
  { nodeName: 'ul', Component: RichTextUl },
  { nodeName: 'h2', Component: RichTextHeadingTwo },
  { nodeName: 'a', Component: RichTextHyperlink },
]

const Body = ({ text, attributes }: BodyProps) => {
  return <Component {...attributes}>{parseBuilderRichText(text, replacements)}</Component>
}

export default Body
