import React from 'react'
import { ThemeProvider, DefaultTheme } from 'styled-components'
import PropTypes from 'prop-types'

export const themeObj: DefaultTheme = {
  breakpoints: {
    small: '600px',
    medium: '900px',
    large: '1200px',
    extraLarge: '1320px',
  },
  // Colors
  primaryGreen: '#00875B', // brandOne
  secondaryGreen: '#005b32', // brandFour
  primaryBlue: '#0A6DFF', // brandTwo
  primaryRed: '#CF2D2D', // brandThree
  primaryWhite: '#FFFFFF', // textOne
  secondaryWhite: '#EFF1ED', // NEW created based on repeated colors
  primaryBlack: '#232C33', // textTwo
  secondaryBlack: '#44484C', // textThree
  tertiaryBlack: '#1B1E28', // textFour
  primaryGray: '#868f96', // textFive
  secondaryGray: '#D3D5D6', // textSix
  tertiaryGray: '#6E7581', // textSeven
  applyOpacity: (hex: string, opacity: number) => {
    const hexWithoutHash = hex.replace('#', '')
    const r = parseInt(hexWithoutHash.substring(0, 2), 16)
    const g = parseInt(hexWithoutHash.substring(2, 4), 16)
    const b = parseInt(hexWithoutHash.substring(4, 6), 16)

    return `rgba(${r}, ${g}, ${b}, ${opacity})`
  },
  // Fonts
  fontName: 'Poppins, sans-serif',
  // General Font Sizes
  fontSizes: {
    h1: '3.2rem',
    h1Medium: '5rem',
    h1Large: '6rem',
    h2: '4rem',
    h2Medium: '6rem',
    h3: '2.6rem',
    h3Medium: '4rem',
    h4: '2rem',
    h4Medium: '2.6rem',
    h5: '2rem',
    h6: '1.6rem',
    h6Medium: '1.8rem',
    paragraph: '1.6rem',
    paragraphMedium: '1.8rem',
  },
  // General Rich Text (Body) Font Sizes
  richText: {
    paragraph: '1.5rem',
    paragraphMedium: '1.8rem',
    h1: '3.2rem',
    h1Medium: '5rem',
    h2: '2.6rem',
    h2Medium: '4rem',
    h3: '2rem',
    h3Medium: '2.6rem',
    h4: '2rem',
    h4Medium: '2rem',
  },

  widthContainer: '123.2rem',
  widthDocumentGutter: '3rem',
}

const Theme = ({ children }: any) => <ThemeProvider theme={themeObj}>{children}</ThemeProvider>

Theme.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Theme
