import { Builder } from '@builder.io/react'
import Body from './body'

Builder.registerComponent(Body, {
  name: 'Body',
  inputs: [{ name: 'text', type: 'richText', defaultValue: '<p>Body text here...</p>' }],
  noWrap: true,
  defaultStyles: {
    margin: '0 auto 15rem auto',
    padding: '0 3rem',
    maxWidth: '81.5rem',
  },
})
