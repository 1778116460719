exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-edit-builder-symbol-tsx": () => import("./../../../src/pages/edit-builder-symbol.tsx" /* webpackChunkName: "component---src-pages-edit-builder-symbol-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-responsible-gaming-video-tsx": () => import("./../../../src/pages/responsible-gaming-video.tsx" /* webpackChunkName: "component---src-pages-responsible-gaming-video-tsx" */),
  "component---src-templates-blog-post-archive-blog-post-archive-tsx": () => import("./../../../src/templates/BlogPostArchive/blog-post-archive.tsx" /* webpackChunkName: "component---src-templates-blog-post-archive-blog-post-archive-tsx" */),
  "component---src-templates-blog-post-blog-post-tsx": () => import("./../../../src/templates/BlogPost/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-blog-post-tsx" */),
  "component---src-templates-blog-post-category-archive-blog-post-category-archive-tsx": () => import("./../../../src/templates/BlogPostCategoryArchive/blog-post-category-archive.tsx" /* webpackChunkName: "component---src-templates-blog-post-category-archive-blog-post-category-archive-tsx" */),
  "component---src-templates-catch-all-tsx": () => import("./../../../src/templates/CatchAll.tsx" /* webpackChunkName: "component---src-templates-catch-all-tsx" */),
  "component---src-templates-seo-landing-page-seo-landing-page-tsx": () => import("./../../../src/templates/SeoLandingPage/seo-landing-page.tsx" /* webpackChunkName: "component---src-templates-seo-landing-page-seo-landing-page-tsx" */),
  "component---src-templates-utility-utility-tsx": () => import("./../../../src/templates/Utility/utility.tsx" /* webpackChunkName: "component---src-templates-utility-utility-tsx" */),
  "component---src-templates-video-post-archive-video-post-archive-tsx": () => import("./../../../src/templates/VideoPostArchive/video-post-archive.tsx" /* webpackChunkName: "component---src-templates-video-post-archive-video-post-archive-tsx" */),
  "component---src-templates-video-post-category-archive-video-post-category-archive-tsx": () => import("./../../../src/templates/VideoPostCategoryArchive/video-post-category-archive.tsx" /* webpackChunkName: "component---src-templates-video-post-category-archive-video-post-category-archive-tsx" */)
}

